export default {
  env: 'production',
  version: "1.4.69",
  api: {
    base_url:
      'https://us-central1-totemic-bonus-239316.cloudfunctions.net/api/',
  },
  integrations: {
    stripe: {
      key: 'pk_test_51J0X48CB1dfLO0v7p9krpHXCl6D30voPfsb6xdXse8ea368xRhyfs7QLB27zPpK0SZkbY8NfkAWiO2cwAFjLIoSH00hH09hepr',
      secret:
        'sk_test_51J0X48CB1dfLO0v7Bw5N4JIVOYCnpyohWmIPsPNL84UYQFlQ5xzkjIxWgx38S48zBzf4hNK0AfTWD6YPcS7w8R2E00oHXVKyHk',
    },
    track_js: {
      token: '6d9aba7876784482b2ddd727803a0b1f',
      application: 'ww0geqrvjird0rt4hjs3gkdh9',
      console: { display: false },
    },
    firebase: {
      config: {
        apiKey: 'AIzaSyAz_70O4sDQ9Vc7cmVDdJ-WikgC7R1Uvaw',
        authDomain: 'totemic-bonus-239316.firebaseapp.com',
        projectId: 'totemic-bonus-239316',
        storageBucket: 'totemic-bonus-239316.appspot.com',
        messagingSenderId: '1013363213877',
      },
    },
    adobe_sign: {
      client_id: 'CBJCHBCAABAAT5hSWPjrVaMSDlB78FLxzsM24XEvT0s5',
    },
    google: {
      analytics: {
        tag: 'G-STKT5NDS2X'
      }
    }
  },
}