export default {
  env: 'beta',
  version: "1.4.69",
  api: {
    base_url:
      'https://us-central1-usabg-backoffice-beta.cloudfunctions.net/api/',
  },
  integrations: {
    stripe: {
      key: 'pk_test_51J0X48CB1dfLO0v7p9krpHXCl6D30voPfsb6xdXse8ea368xRhyfs7QLB27zPpK0SZkbY8NfkAWiO2cwAFjLIoSH00hH09hepr',
      secret:
        'sk_test_51J0X48CB1dfLO0v7Bw5N4JIVOYCnpyohWmIPsPNL84UYQFlQ5xzkjIxWgx38S48zBzf4hNK0AfTWD6YPcS7w8R2E00oHXVKyHk',
    },
    track_js: {
      token: '6d9aba7876784482b2ddd727803a0b1f',
      application: 'acdtk00ko6qeb8hgogqyshf2c',
    },
    firebase: {
      config: {
        apiKey: 'AIzaSyCfi4gfdQJL2DeCdAV2nEN98ucNgjAki_4',
        authDomain: 'usabg-backoffice-beta.firebaseapp.com',
        projectId: 'usabg-backoffice-beta',
        storageBucket: 'usabg-backoffice-beta.appspot.com',
        messagingSenderId: '444081365797',
      },
    },
    adobe_sign: {
      client_id: 'CBJCHBCAABAAT5hSWPjrVaMSDlB78FLxzsM24XEvT0s5',
    },
    google: {
      analytics: {
        tag: 'G-L6V075GZE4'
      }
    }
  },
}